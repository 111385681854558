@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Lavishly+Yours&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Parisienne&family=Playfair+Display&family=Crimson+Text&family=Tangerine&family=Righteous&family=Abel&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* transition: all 0.5s ease; */
}

html, body {
  min-height: 100% !important;
  height: 100%;
  overflow-x: hidden;
}

.site-wrapper {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
}

.object-bottom {
  background-size: contain;
  background-position: center;
}

.selected {
  color: white;
  background-color: #e0b092;
}

.iframe-container{
  width: 30%;
  min-width: 320px;
  height: 575px;
  position: "relative";
  margin: 20px;
}

.iframe {
  width: 100%;
  height: 100%;
  position: relative;
  border: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --toastify-color-progress-light: linear-gradient(
    #e0b092,
    #e0b092
  ) !important;
}

.toast-message {
  font-family: "Baskervville" !important;
  font-size: 14px;
}

.stripe-button {
  color: #e0b092;
  /* color: white; */
  min-width: 100px;
  padding: 10px 15px;
  margin: 10px 0;
  font-size: 20px;
  border-radius: 20px;
  font-family: 'Righteous', sans-serif;
  font-weight: bold;
  border: solid 1px white;
  background-color: white;
}

.StripeCheckout span, .StripeCheckout {
  background-image: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  height: auto !important;
  color: #e0b092 !important;
  color: #b4c2cb !important;
  /* color: white; */
  min-width: 100px !important;
  font-size: 18px !important;
  border-radius: 20px;
  font-family: 'Raleway', sans-serif !important;
  text-decoration: none !important;
  font-weight: 400 !important;
}

ul li {
  margin-left: 20px;
}